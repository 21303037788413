<template>
<div>
  <div class="ReportingMainContainer D-Flex">
    <div id="ReportingTabs" class="TabContainer BG4 color2 D-Flex M-Auto">
      <router-link class="RepTabButton F-Bold" :class="$route.name === 'ReportingOrders' && 'router-link-active'" :to="{ name: 'ReportingOrders', params: { Type: 'order' } }">{{LanguageSelector('Orders', 'الطلبات')}}</router-link>
      <router-link class="RepTabButton F-Bold" to="/Reports/Revenue">{{LanguageSelector('Revenue', 'الايرادات')}}</router-link>
      <router-link class="RepTabButton F-Bold" to="/Reports/Expenses">{{LanguageSelector('Expenses', 'المصاريف')}}</router-link>
    </div>
    <div class="D-Flex FlexWrap DateContainer" style="justify-content: center;" :class="$route.path.includes('Orders/order') ? 'Disabled' : ''">
      <div><span>{{LanguageSelector('From', 'من')}}</span><input @change="DateChanged(DateFrom, DateTo)" v-model="DateFrom" class="CookiesInput color2" type="date"></div>
      <div><span>{{LanguageSelector('To', 'الى')}}</span><input @change="DateChanged(DateFrom, DateTo)" v-model="DateTo" class="CookiesInput color2" type="date"></div>
    </div>
    <div class="W-100 M-Auto ReportFontSize">
      <router-view style="padding: 2em"></router-view>
    </div>
  </div>
</div>
</template>

<script>
export default {
  created () {},
  data () {
    return {
      DateFrom: this.$store.state.CurrentDate.From,
      DateTo: this.$store.state.CurrentDate.To
    }
  },
  methods: {}
}
</script>
<style scoped>
.ReportingMainContainer{
  width: 100%;
  flex-direction: column;
  font-size:0.8vw;
}
/* .ReportingMainContainer>*{
  margin: auto auto 2em auto;
} */
.RepTabButton{
  padding: 0.5em 3em;
  margin: 0.15em;
  text-decoration: none;
  filter: drop-shadow(var(--shadow2));
  color: v-bind('$store.getters.Theme.color3');
}
.TabContainer{
  padding: 0.3em;
  border-radius: 0 0 var(--rad3) var(--rad3);
;
}
.router-link-active{
  background: v-bind('$store.getters.Theme.BG3');
  border-radius: var(--rad3);
  color: v-bind('$store.getters.Theme.color1');
  /* color: var(--colorw2); */
}
.ReportFontSize{
  font-size:0.8vw;
}
@media (max-width: 1000px) {
  .ReportingMainContainer{
    font-size:2.2vw;
  }
  .TabContainer{
    font-size: 2.2vw;
  }
}
@media (min-width: 1000px) {
  .DateContainer{
    position: absolute;
    left: 5.5em;
  }
}
</style>
